import { UAPI, errCheck } from './API';

export class Auth {
    async login(data) {
        try {
            return await UAPI.post('admin/login', data);
        } catch (e) {
            return errCheck(e);
        }
    }
}
