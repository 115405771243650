<script>
/* eslint-disable no-unused-vars */
import { reactive, ref } from 'vue';
import { Auth } from '@/service/Auth';
import { validate } from '@/validations/Auth/Login';
import { Token } from '@/service/Token';
import { useRouter } from 'vue-router';
import { inject } from 'vue';

export default {
    setup() {
        const auth = new Auth();
        const errors = ref({});
        const token = new Token();
        const router = useRouter();
        const store = inject('store');

        const formData = reactive({ email: '', password: '' });
        const modal = reactive({ display: false, message: null });
        const modalIp = reactive({ display: false, message: null });

        const close = () => (modal.display = false);
        const closemodalIp = () => (modalIp.display = false);

        const submitHendler = async () => {
            let validation = validate(formData);
            if (validation.isInvalid) {
                errors.value = validation.error;
            } else {
                let checking = await auth.login(formData);
                if (checking.status === 200) {
                    // console.log(checking.data)
                    // store.state.user.userID = checking.userID;
                    authenticated(checking.data);
                } else if (checking.status === 401) {
                    errors.value = {};
                    modalIp.display = true;
                    modalIp.message = '접근이 불가능한 계정입니다.';
                } else if (checking.status === 400) {
                    errors.value = {};
                    modal.display = true;
                    if (checking.data.error == 'Email does not exists') {
                        modal.message = '존재하지 않은 아이디(이메일주소)입니다.';
                    } else if (checking.data.error == '허용되지 않는 접근입니다.') {
                        modal.message = '접근 불가능한 IP 입니다.';
                    } else {
                        modal.message = '패스워드가 일치하지 않습니다.';
                    }
                } else {
                    errors.value = {};
                    modal.display = true;
                    modal.message = checking.response.data.error;
                }
            }
        };

        const authenticated = async (data) => {
            store.state.user.userID = data.userId;
            token.setWithExpiry(
                'user',
                JSON.stringify({
                    token: data.accessToken,
                }),
                data.accessTokenExpiry * 1000
            );
            token.setWithExpiry(
                'refreshToken',
                JSON.stringify({
                    token: data.refreshToken,
                }),
                data.refreshTokenExpiry * 1000
            );

            router.push({ path: '/' });
        };

        const userHome = () => {
            window.location.href = process.env.VUE_APP_BASE_FRONT_URL;
        };

        return {
            formData,
            errors,
            modal,
            close,
            submitHendler,
            modalIp,
            closemodalIp,
            userHome,
        };
    },
};
</script>

<template>
    <Toast />
    <div class="login-body">
        <div class="login-wrapper">
            <div class="login-panel">
                <router-link :to="{ path: '/' }" class="logo">
                    <img style="width: 100%" src="assets/layout/images/logo.png" alt="lse-logo" />
                </router-link>
                <div class="card login-298">
                    <form @submit.prevent="submitHendler">
                        <h3 class="welcome-title">관리자 로그인</h3>
                        <br />
                        <p class="welcome-subtitle">계정에 로그인해주세요.</p>
                        <div class="p-field p-fluid">
                            <!-- <label for="username">이메일</label> -->
                            <InputText id="email" placeholder="아이디를 입력하세요." :class="{ 'p-invalid': errors?.email }" v-model="formData.email" />
                            <small class="text-red">{{ errors?.email }}</small>
                        </div>
                        <div class="p-field p-fluid">
                            <!-- <label for="password">비밀번호</label> -->
                            <Password :class="{ 'p-invalid': errors?.password }" :feedback="false" id="password" v-model="formData.password" placeholder="비밀번호를 입력하세요." />
                            <small class="text-red">{{ errors?.password }}</small>
                        </div>
                        <Button label="로그인" class="login-btn" type="submit"></Button>
                        <Button label="Go to main homepage" class="login-btn outline" @click="userHome"></Button>
                        <!-- <p class="forgot-password">비밀번호를 잊으셨나요? <router-link :to="{ path: '/login', query: { paCode: 'ft_pass' } }">지금 재설정</router-link></p> -->
                    </form>
                </div>
            </div>
        </div>

        <Dialog :closable="false" v-model:visible="modal.display" :breakpoints="{ '960px': '75vw' }" :style="{ width: '20vw' }" :modal="true">
            <div id="alert-298" class="p-field p-fluid text-center">
                <p class="msg-content"><b>실패하였습니다</b></p>
                <p class="msg-content">
                    {{ modal.message }}
                </p>
                <!-- <p class="msg-content">
                    존재하지 않는 이메일 주소입니다. <br /><br />
                    이메일을 다시 입력해주세요.
                </p> -->
            </div>
            <template #footer>
                <Button label="확인" @click="close()" icon="pi pi-check" class="p-button-primary" />
            </template>
        </Dialog>
        <Dialog :closable="false" v-model:visible="modalIp.display" :breakpoints="{ '960px': '75vw' }" :style="{ width: '20vw' }" :modal="true">
            <div id="alert-298" class="p-field p-fluid text-center">
                <p class="msg-content"><b>실패하였습니다</b></p>
                <p class="msg-content">
                    {{ modalIp.message }}
                </p>
            </div>
            <template #footer>
                <Button label="확인" @click="closemodalIp()" icon="pi pi-check" class="p-button-primary" />
            </template>
        </Dialog>
    </div>
    <!-- <FormLayoutDemoVue /> -->
</template>

<style lang="scss">
#alert-298 {
    .msg-content {
        text-align: center;
    }
}

#otpModal-alert-298 {
    .msg-content {
        text-align: center;
    }
}

#otpSetModal-alert-298 {
    .msg-content {
        text-align: center;
    }
}

#qr-code-298 {
    img {
        width: 100%;
    }
}

/* Chrome, Safari, Edge, Opera */
#otp-pin::-webkit-outer-spin-button,
#otp-pin::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
#otp-pin[type='number'] {
    -moz-appearance: textfield;
}

.login-body {
    .login-wrapper {
        .login-panel {
            .welcome-title {
                margin: 0;
                font-size: 22px;
                font-weight: 500;
            }

            .welcome-subtitle {
                margin: 0;
                font-size: 14px;
                margin-bottom: 15px;
            }

            .forgot-password {
                margin: 0;
                font-size: 10px;

                // margin-top: 15px;
                a {
                    color: #000;
                    text-decoration: underline;
                }
            }

            .card.login-298 {
                max-width: 100% !important;
                width: 100% !important;

                .p-field.p-fluid {
                    margin-bottom: 2rem !important;

                    label {
                        display: block !important;
                        float: left;
                    }

                    small {
                        text-align: left !important;
                        float: left !important;
                        width: 100% !important;
                    }

                    div {
                        display: block;
                    }
                }

                .login-btn {
                    width: 100%;
                    margin-bottom: 10px;

                    &.outline {
                        background: #fff;
                        border: 1px solid #464df2;
                        color: #464df2;
                    }
                }
            }
        }
    }
}
</style>
